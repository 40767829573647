<template>
  <div class="flex flex-1 flex-col gap-2 border-b border-grid px-5 py-2">
    <div class="flex flex-1 flex-col">
      <div class="mt-2 text-xs font-semibold text-gray-100">Instructions</div>
      <div
        class="mt-3 flex flex-1 flex-col gap-2 pr-2"
        v-for="(instruction, index) in instructions"
        :key="instruction"
        v-show="index + 1 == page"
      >
        <div class="h-8 text-xs font-medium text-gray-100">{{ page }}. <span v-html="instruction" /></div>
        <div class="mt-2 flex h-64 w-full items-center justify-center">
          <a
            target="_blank"
            :href="`https://the-tie-sigdev.s3.us-east-2.amazonaws.com/alert_instructions/discord_${page}.png`"
          >
            <img
              class="max-w-3/4 m-auto h-auto max-h-64 w-auto rounded"
              :src="`https://the-tie-sigdev.s3.us-east-2.amazonaws.com/alert_instructions/discord_${page}.png`"
            />
          </a>
        </div>
      </div>
    </div>
    <BasePaginate v-model="page" :pages="3" class="mx-auto mb-4 mt-10 w-96" />
  </div>
</template>

<script setup>
import { ref } from 'vue';

const page = ref(1);

const instructions = ref([
  `Choose a server and channel you would like to send alerts in. Click the gear icon next to this channel.`,
  `In the left hand panel, select <span class="rounded bg-gray-700 px-1"> Integrations</span>. In this page,
          you will see an option for <span class="rounded bg-gray-700 px-1">Webhooks</span>. Here you can select
          <span class="rounded bg-gray-700 px-1">View Webhooks</span>.`,
  `On the next page, select the blue <span class="rounded bg-gray-700 px-1">New Webhook</span> button on the
          next page to create your webhook. Select <span class="rounded bg-gray-700 px-1">Copy Webhook URL</span> and
          paste into the <span class="rounded bg-gray-700 px-1">Link</span> field above.`
]);
</script>
